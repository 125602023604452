.main-nav {
    margin: 3rem 0;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            button {
                display: flex;
                border-radius: 0px 3px 3px 0px;
                padding: 0.625rem 0.75rem;
                color: #1F2933;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 143%; /* 20.02px */
                letter-spacing: 0.252px;
                border: 0;
                width: 100%;
                background: transparent;
                justify-content: flex-start;
                align-items: center;

                &.selected {
                    border-left: 2px solid #6CB8D7;
                    background: #EFF7FA;
                    font-weight: 600;
                }
            }

            &.has-children {
                > button {
                    padding-left: 0.5rem;
                    gap: 0.1rem;
                }

                ul {
                    padding: 0;
                    display: none;

                    li {
                        padding-left: 0.5rem;

                        button {
                            text-align: left;
                        }
                    }

                    &.open {
                        display: block;
                    }
                }
            }
        }
    }
}