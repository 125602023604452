@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Inter', helvetica, sans-serif;
    box-sizing: border-box;
}

html {
    font-size: 100%;
}


body {    
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.body-wrap {
    display: grid;
    min-height: 100vh; 
    grid-template-columns: 15.625rem repeat(11, 1fr);
    grid-template-rows: 4.375rem auto;

    header {
        grid-row: 1;
        grid-column: 1 / span 12;
        display: flex;
        align-items: center;
        padding: 1rem 2.25rem;
        justify-content: space-between;
        background: #FFF;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(20px);
        position: relative;

        .left, .right {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        .right {
            label {
                font-size: 0.75rem;
                line-height: 1.5;
            }
        }

        .left {
            gap: 1.5rem;
        }

        h1 {
            font-size: 20px;
            color: #020917;
            font-weight: 300;
            font-family: 'Inter', Helvetica, sans-serif;
            display: flex; 
            align-items: center; 
            gap: 1.5rem;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 0.2px;
        }

        .MuiSvgIcon-root {
            fill: #020917ff;
        }

        .badge {
            width: 16px;
            height: 16px;
            border-radius: 85px;
            background-color: #C117BA;
            position: absolute;
            top: 5px;
            right: 5px;
            color: white;
            font-size: 10px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            font-weight: 600;
        }
    }

    aside {
        grid-column: 1 ;
        grid-row: 2;
        padding: 1rem 2.25rem;
        max-width: 250px;
        max-height: calc(100vh - 70px);
        overflow: hidden;
        overflow-y: auto; 

        h2 {
            margin: 0;
            padding: 0;
            color: #020917;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.5;
        }

        a {
            color: #585858;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.15px; 
            text-decoration-line: underline;
        }
    }

    main {
        grid-row: 2;
        grid-column: 2 / span 11;
        max-height: calc(100vh - 70px);

        .main-iframe {
            width: 100%;
            height: 100%;
            border: 0;

        }
    }
}
