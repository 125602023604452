@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Inter", helvetica, sans-serif;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

.body-wrap {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 15.625rem repeat(11, 1fr);
  grid-template-rows: 4.375rem auto;
}
.body-wrap header {
  grid-row: 1;
  grid-column: 1/span 12;
  display: flex;
  align-items: center;
  padding: 1rem 2.25rem;
  justify-content: space-between;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  position: relative;
}
.body-wrap header .left, .body-wrap header .right {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.body-wrap header .right label {
  font-size: 0.75rem;
  line-height: 1.5;
}
.body-wrap header .left {
  gap: 1.5rem;
}
.body-wrap header h1 {
  font-size: 20px;
  color: #020917;
  font-weight: 300;
  font-family: "Inter", Helvetica, sans-serif;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.2px;
}
.body-wrap header .MuiSvgIcon-root {
  fill: #020917;
}
.body-wrap header .badge {
  width: 16px;
  height: 16px;
  border-radius: 85px;
  background-color: #C117BA;
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: 600;
}
.body-wrap aside {
  grid-column: 1;
  grid-row: 2;
  padding: 1rem 2.25rem;
  max-width: 250px;
  max-height: calc(100vh - 70px);
  overflow: hidden;
  overflow-y: auto;
}
.body-wrap aside h2 {
  margin: 0;
  padding: 0;
  color: #020917;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
}
.body-wrap aside a {
  color: #585858;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
}
.body-wrap main {
  grid-row: 2;
  grid-column: 2/span 11;
  max-height: calc(100vh - 70px);
}
.body-wrap main .main-iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.main-nav {
  margin: 3rem 0;
}
.main-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.main-nav ul li button {
  display: flex;
  border-radius: 0px 3px 3px 0px;
  padding: 0.625rem 0.75rem;
  color: #1F2933;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%; /* 20.02px */
  letter-spacing: 0.252px;
  border: 0;
  width: 100%;
  background: transparent;
  justify-content: flex-start;
  align-items: center;
}
.main-nav ul li button.selected {
  border-left: 2px solid #6CB8D7;
  background: #EFF7FA;
  font-weight: 600;
}
.main-nav ul li.has-children > button {
  padding-left: 0.5rem;
  gap: 0.1rem;
}
.main-nav ul li.has-children ul {
  padding: 0;
  display: none;
}
.main-nav ul li.has-children ul li {
  padding-left: 0.5rem;
}
.main-nav ul li.has-children ul li button {
  text-align: left;
}
.main-nav ul li.has-children ul.open {
  display: block;
}

/* Switch/Toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #a9aca8 url(../images/icon_cross.png) no-repeat 28px center;
  transition: 0.4s;
  border-radius: 34px;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  top: 3px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background: #5066c5 url(../images/icon_check.png) no-repeat 9px center;
}
.switch input:checked + .slider:before {
  transform: translateX(24px);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #047A9C;
}
.switch input:disabled + .slider, .switch input[disabled] + .slider {
  background-color: #C2C7CC;
}

/*@import './header';
@import './sidebar';
@import './switch';
@import './optionsmenu';
@import './tabular';
@import './caheader';
@import './searchfilter';
@import './sections';
@import './addcontent';
@import './selections';
@import'./review';
@import'./filters'; *//*# sourceMappingURL=styles.css.map */